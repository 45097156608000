import Navbar from './components/navbar';
import Contact from './components/contact';
import Carousel from './components/carousel1';
import Menu from './components/menu';
import Footer from './components/footer';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
// images
import bgimg from './assets/img/resto.jpg';
import logoBgnon from './assets/img/logoBgnon.png';
import chef from './assets/img/chef.jpg';
import Modal from './components/modal';

function App() {

  return (
    <div className=' font-myFont'>
     
     <div className="h-screen text-blanc bg-dark">

       <div id="home" className="absolute top-0 w-full md:w-full h-full md:h-full bg-center bg-cover " 
        style={{backgroundImage: `url(${bgimg})`}}>
        <span id="blackOverlay" className="w-full h-full absolute opacity-50 bg-black"></span>
        </div> 
        <Navbar />
      </div>
        <section className='absolute top-24 left-20 right-20 bottom-24 flex justify-center items-center'>
          <img src={logoBgnon} alt="" className='h-24 md:h-48  ' />
          
        </section>
        <section id="about" className='bg-dark h-auto md:h-screen'>
          <div className="py-8 md:py-10 lg:py-24">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="sm:text-center">
                <p className="mt-2 text-3xl font-bold tracking-tight text-center text-white sm:text-3xl">A PROPOS DE NOUS</p>
              </div>

              <div className="mt-6 flex-row md:flex items-center ">

                <div>
                  <img src={chef} alt="" className='w-2/4 md:w-3/4 lg:w-2/4 ml-24 md:ml-0' />
                </div>
                <div className="mt-4 w-full md:w-3/4">
                  <p className="mt-2 leading-7 text-white text-xl md:text-lg lg:text-xl">Arrivée en exil en France et après 13 années passées dans la restauration à explorer les cuisines du monde entier, je viens finalement à la réalisation de mon rêve - le <span className='font-semibold'>TIBET CORNER</span> .
                  </p> <br />
                  <p className="mt-2 leading-7 text-white text-xl md:text-lg lg:text-xl">Le Tibet Corner est la culture culinaire tibétaine qui s'est intégrée au savoir acquis au cours des 13 dernières années. Le <span className='font-semibold'>COIN TIBÉTAIN</span> est avant tout la passion d'une personne, l'amour de ses origines dans le respect de la cuisine.</p>
                </div>
              </div>
            </div>
          </div>
        </section>


    <Modal />
        <section id="menu" className='pb-12 md:pb-0 md:h-screen '>
        <h2 className="pt-36 md:pt-16 text-2xl font-bold text-center tracking-tight text-dark sm:text-3xl">MENU / CARTES </h2>
        <Menu />
        </section>
        <section id='gallery' className='py-8 md:h-screen bg-dark'>
        <Carousel />
        </section>

        <section className='h-screen mb-96 lg:mb-0' id='contact'>
          <div className='flex justify-center'>
            <h2 className="m-4 p-6 w-6/12 text-3xl font-bold text-center tracking-tight text-dark sm:text-3xl">CONTACT </h2>
          </div>     
        <Contact />
        </section>

        <section className='bg-dark'>
          <Footer />
        </section>
    </div>
  );
}

export default App;
