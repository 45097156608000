import React from "react";
import { Carousel } from "react-responsive-carousel";
import pic1 from '../assets/img/1.jpg';
import pic2 from '../assets/img/2.jpg';
import pic3 from '../assets/img/3.jpg';
import pic4 from '../assets/img/4.jpg';
import pic5 from '../assets/img/5.jpg';
import pic6 from '../assets/img/6.jpg';
import pic7 from '../assets/img/7.jpg';
import pic8 from '../assets/img/8.jpg';
import pic9 from '../assets/img/9.jpg';
import pic10 from '../assets/img/10.jpg';
import pic11 from '../assets/img/11.jpg';
import pic12 from '../assets/img/12.jpg';
import pic13 from '../assets/img/13.jpg';
import pic14 from '../assets/img/14.jpg';

const Carousel1 = () => (
  <Carousel autoPlay className="max-w-3xl mx-auto">
    <div>
      <img alt="tibet corner" src={pic14} />
    </div>
    <div>
      <img alt="tibet corner" src={pic13} />
    </div>
    <div>
      <img alt="tibet corner" src={pic12} />
    </div>
    <div>
      <img alt="tibet corner" src={pic11} />
    </div>
    <div>
      <img alt="tibet corner" src={pic10} />
    </div>
    <div>
      <img alt="tibet corner " src={pic9} />
    </div>
    <div>
      <img alt="tibet corner chicken lollipop " src={pic8}/>
    </div>
    <div>
      <img alt="tibet corner tofu" src={pic7} />
    </div>
    <div>
      <img alt="tibet corner shabaglap" src={pic6} />
    </div>
    <div>
      <img alt="tibet corner mix-veg" src={pic5} />
    </div>
    <div>
      <img alt="tibet corner shapta" src={pic4} />
    </div>
    <div>
      <img alt="tibet corner laphing" src={pic3} />
    </div>
    <div>
      <img alt="tibet corner porc" src={pic2} />
    </div>
    <div>
      <img alt="tibet corner momo" src={pic1} />
    </div>
  </Carousel>
);
export default Carousel1;