function Footer() {
    return(
        <section className="bottom-0 ">
            <div className="max-w-screen-xl px-4 py-6 mx-auto space-y-4 overflow-hidden md:px-4 lg:px-8 md:pt-80 lg:py-12">
                <nav className="flex flex-wrap justify-center -mx-5 -my-2 text-white ">
                    <div className="px-5 py-2">
                        <a href="#home" className="text-base leading-6 hover:text-vibrant">
                            ACCUEIL
                        </a>
                    </div>
                    <div className="px-5 py-2">
                        <a href="#about" className="text-base leading-6 hover:text-vibrant">
                            A PROPOS DE
                        </a>
                    </div>
                    <div className="px-5 py-2">
                        <a href="#menu" className="text-base leading-6 hover:text-vibrant">
                            MENU
                        </a>
                    </div>
                    <div className="px-5 py-2">
                        <a href="#gallery" className="text-base leading-6 hover:text-vibrant">
                            
                        GALERIE
                        </a>
                    </div>
                    <div className="px-5 py-2">
                    <a href="#contact" className="text-base leading-6 hover:text-vibrant">
                        CONTACT
                    </a>
                    </div>
                </nav>
                <div className="flex justify-center mt-8 space-x-6">
                {/* <a href="#" className="text-gray-400 hover:text-gray-500">
                    <span className="sr-only">Facebook</span>
                    <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                        <path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd"></path>
                    </svg>
                </a> */}
                <a href="https://www.instagram.com/tibetcornerparis/?igshid=YmMyMTA2M2Y%3D" className="text-gray-400 hover:text-gray-500" target="_blank" rel="noreferrer">
                    <span className="sr-only">Instagram</span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" className='w-8 h-8'><radialGradient id="yOrnnhliCrdS2gy~4tD8ma" cx="19.38" cy="42.035" r="44.899" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#fd5"/><stop offset=".328" stopColor="#ff543f"/><stop offset=".348" stopColor="#fc5245"/><stop offset=".504" stopColor="#e64771"/><stop offset=".643" stopColor="#d53e91"/><stop offset=".761" stopColor="#cc39a4"/><stop offset=".841" stopColor="#c837ab"/></radialGradient><path fill="url(#yOrnnhliCrdS2gy~4tD8ma)" d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20 c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20 C42.014,38.383,38.417,41.986,34.017,41.99z"/><radialGradient id="yOrnnhliCrdS2gy~4tD8mb" cx="11.786" cy="5.54" r="29.813" gradientTransform="matrix(1 0 0 .6663 0 1.849)" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#4168c9"/><stop offset=".999" stopColor="#4168c9" stopOpacity="0"/></radialGradient><path fill="url(#yOrnnhliCrdS2gy~4tD8mb)" d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20 c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20 C42.014,38.383,38.417,41.986,34.017,41.99z"/><path fill="#fff" d="M24,31c-3.859,0-7-3.14-7-7s3.141-7,7-7s7,3.14,7,7S27.859,31,24,31z M24,19c-2.757,0-5,2.243-5,5 s2.243,5,5,5s5-2.243,5-5S26.757,19,24,19z"/><circle cx="31.5" cy="16.5" r="1.5" fill="#fff"/><path fill="#fff" d="M30,37H18c-3.859,0-7-3.14-7-7V18c0-3.86,3.141-7,7-7h12c3.859,0,7,3.14,7,7v12 C37,33.86,33.859,37,30,37z M18,13c-2.757,0-5,2.243-5,5v12c0,2.757,2.243,5,5,5h12c2.757,0,5-2.243,5-5V18c0-2.757-2.243-5-5-5H18z"/></svg>
                </a>
                {/* <a href="#" className="text-gray-400 hover:text-gray-500">
                    <span className="sr-only">Twitter</span>
                    <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path>
                    </svg>
                </a> */}
                </div>
                <p className="text-xs md:text-base leading-6 text-center text-gray-200">
                    © 2022 TibetCorner, All rights reserved. 
                </p>
                <div className="flex justify-center items-center">
                    <p className="pr-4 text-gray-200">Created by</p>
                    <a href="https://www.urbantibet.com/" target='_blank' rel="noreferrer" className="font-semibold cursor-pointer text-white"><span className="text-vibrant">Urban</span>Tibet</a>

                </div>
            </div>
        </section>
    )
}

export default Footer;