import entree from '../assets/menu/Menu Dark 2.png';
import dessert from '../assets/menu/Menu Dark 4.png';
import drinks from '../assets/menu/Menu Dark back.png';
import plat from '../assets/menu/Menu Dark 3.png';
import front from '../assets/menu/Menu Dark front.png';
import back from '../assets/menu/Menu Dark 1.png';

function Menu(){
    return(
            <div className="flex justify-evenly items-center ">
                <div className='hidden w-36 md:w-96 sm:w-56 sm:block'>
                    <img src={front} alt=""></img>
                </div>
                 
                <div className="flex flex-col justify-evenly items-center mt-8">

                    <div className="mb-8 lg:mb-12 ">
                        <div className="flex items-start">
                            <a href={entree} target='_blank' rel="noreferrer"><button type="button" className="text-white w-32 md:w-44 hover:text-black bg-vibrant hover:bg-creme font-medium rounded-lg text-lg text-semibold px-2 md:px-6 py-2.5 text-center tracking-widest mr-2 mb-2">Entrée</button></a>
                        </div>
                    </div>
                    <div className="mb-8 lg:mb-12 ">
                        <div className="flex items-center">
                        <a href={plat} target='_blank' rel="noreferrer"><button type="button" className="text-white w-32 md:w-44 hover:text-black bg-vibrant hover:bg-creme font-medium rounded-lg text-lg text-semibold px-2 md:px-6 py-2.5 text-center tracking-widest mr-2 mb-2">Plat</button></a>
                        
                        </div>
                    </div>
                    <div className="mb-8 lg:mb-12 ">
                        <div className="flex align-start">
                        
                        <a href={dessert} target='_blank' rel="noreferrer"><button type="button" className="text-white w-32 md:w-44 hover:text-black bg-vibrant hover:bg-creme font-medium rounded-lg text-lg text-semibold px-2 md:px-6 py-2.5 text-center tracking-widest mr-2 mb-2">Dessert</button></a>
                        </div>
                    </div>
                    <div className="mb-8 md:mb-12">
                        <div className="flex align-start">
                    
                        <a href={drinks} target='_blank' rel="noreferrer"><button type="button" className="text-white w-32 md:w-44 hover:text-black bg-vibrant hover:bg-creme font-medium rounded-lg text-lg text-semibold px-2 md:px-6 py-2.5 text-center tracking-widest mr-2 mb-2">Boisson</button></a>
                        
                    </div>
                    
                </div>
                {/* <svg className="w-8 h-8 mt-4 md:mt-0" fill="black" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z"></path></svg>
                    <div className="mb-0 md:mb-12">
                        <p className="text-center font-semibold text-green-600 text-2xl ">Brunch à partir de janvier </p>
                        <p className="uppercase font-semibold text-center text-dark text-2xl">sous réservation  </p>
                    </div> */}
                    <div className="text-white w-full bg-red-800 font-medium rounded-lg text-xl text-semibold p-4 text-center ">
                        <p className='font-bold'>TOUS LES DIMANCHES</p>
                        <p >Brunch à volonté</p>
                        <p>De 11H30 - 14H30</p>
                    </div>
                </div>
                <div className='hidden w-36 md:w-96 sm:w-56 sm:block'>
                    <img src={back} alt="back of the menu card"></img>
                </div>
        </div>
      
        
    )
}
export default Menu;